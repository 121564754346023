<script setup lang="ts">
import { formatPrice } from "~/helpers/formatters";

const { productId, closeDrawer } = defineProps<{
	productId: string;
	closeDrawer: () => void;
}>();

const { addProduct } = useCart();
const { search } = useProductSearch();

defineExpose({
	onDrawerClose: () => {
		addProduct({ id: productId, quantity: 1 });
	}
});

const onAddProduct = () => {
	addProduct({ id: productId, quantity: 1 });

	closeDrawer();
};

const close = () => {
	closeDrawer();
};

const { data: searchResult } = await useCachedAsyncData(`shopware:product:${productId}`, () =>
	search(productId, {
		criteria: {
			includes: {
				product_cross_selling: ["id", "name", "translated.name"]
			},
			associations: {
				crossSellings: {
					limit: 1,
					filter: [
						{
							type: "equals",
							field: "active",
							value: true
						}
					]
				},
				media: {},
				options: {},
				properties: {
					associations: {
						group: {}
					}
				},
				allergens: {}
			}
		}
	})
);
</script>

<template>
	<div class="flex flex-col gap-6">
		<div class="flex flex-col gap-4">
			<KippieButton @click="onAddProduct" size="small">
				Met pan
				{{
					!!searchResult?.product
						? `(${formatPrice(searchResult.product.calculatedPrice.totalPrice || 0, true)})`
						: ""
				}}
			</KippieButton>
			<KippieButton color="white" @click="close" size="small">Alleen de panvulling</KippieButton>
		</div>

		<span class="text-xs text-gray">
			Voor de Kippiepan betaal je
			{{ !!searchResult?.product ? formatPrice(searchResult.product.calculatedPrice.totalPrice || 0, true) : "" }}
			borg. Deze borg krijg je terug als je de pan (schoon) inlevert bij de winkel. Er zit geen uiterlijke termijn
			aan het inleveren van de pan, houd deze gerust thuis om een volgende keer enkel de inhoud te bestellen!
		</span>
	</div>
</template>
