<script setup lang="ts">
import { track } from "@vercel/analytics";
import DepositProductPrompt from "./DepositProductPrompt.vue";
import CrossSellPrompt from "./CrossSellPrompt.vue";
import ContinueShoppingPrompt from "./ContinueShoppingPrompt.vue";
import PickupLocationPrompt from "../shared/PickupLocationPrompt.vue";
import { SHIPPING_METHODS } from "~/lib/constants";
import type { Schemas } from "#shopware";

const { productProp, productIdProp } = defineProps<{
	productProp?: Schemas["Product"];
	productIdProp?: string;
}>();

const { addProduct, selectedPickupLocation } = useCart();

const { onAddToCart: gtagOnAddToCart } = useGtagEvents();
const { addToCart: pinterestAddToCart } = usePinterestTrackingEvents();
const { addedToCartObjectIDs } = useAlgoliaSearchInsights();
const { selectedShippingMethod, setShippingMethod } = useCheckout();
const { search } = useProductSearch();
const drawerProvider = useDrawer();

const productId = computed(() => {
	const id = productProp?.id || productIdProp;
	if (!id) {
		throw new Error("Product ID is required");
	}
	return id;
});

const product = ref<Schemas["Product"] | undefined>();

if (productProp) {
	product.value = productProp;
} else {
	const { data: searchResult } = await useCachedAsyncData(`shopware:product:${productId.value}`, () =>
		search(productId.value, {
			criteria: {
				includes: {
					product_cross_selling: ["id", "name"]
				},
				associations: {
					crossSellings: {
						limit: 1,
						filter: [
							{
								type: "equals",
								field: "active",
								value: true
							}
						]
					},
					media: {},
					options: {},
					properties: {
						associations: {
							group: {}
						}
					},
					allergens: {}
				}
			}
		})
	);
	product.value = searchResult.value?.product;
}

const { isLoading, productAssociations, loadAssociations } = useProductAssociations(
	product as ComputedRef<Schemas["Product"]>,
	{
		associationContext: "cross-selling"
	}
);

const promptDepositProduct = async (productId: string) => {
	await drawerProvider.showDrawer(DepositProductPrompt, {
		drawerTitle: "Elektrische pan erbij?",
		productId
	});
};

const promptCrossSell = async (crossSellChannel: Schemas["ProductCrossSelling"]) => {
	await drawerProvider.showDrawer(CrossSellPrompt, {
		drawerTitle: crossSellChannel.name,
		isLoading: isLoading,
		productAssociations: productAssociations
	});
};

const promptContinueShopping = async () => {
	await drawerProvider.showDrawer(ContinueShoppingPrompt, {
		drawerTitle: "Het artikel is toegevoegd"
	});
};

const onAddToCart = async (e: Event, selectPickupLocation?: boolean) => {
	e.preventDefault();

	// await loadProduct();

	if (!product.value) {
		push.error({
			message:
				"Er is iets misgegaan bij het toevoegen van het product aan je winkelmandje, probeer het later opnieuw."
		});
		return;
	}

	if (selectPickupLocation && selectedShippingMethod.value?.technicalName !== "pickup") {
		setShippingMethod({ id: SHIPPING_METHODS.pickup.id });
	}

	addProduct({
		id: product.value.id,
		quantity: 1
	});

	track("AddToCart", {
		name: product.value.name,
		quantity: 1,
		price: product.value.calculatedPrice.totalPrice
	});
	pinterestAddToCart(product.value);

	try {
		gtagOnAddToCart(product.value, 1);
	} catch (error) {
		console.error("Error onAddToCart", error);
	}

	addedToCartObjectIDs([product.value.id], [product.value]);

	if (!!product.value.crossSellings && product.value.crossSellings?.length > 0) {
		loadAssociations({
			method: "post",
			searchParams: {
				associations: {
					cover: {
						associations: {
							media: {}
						}
					}
				}
			}
		});
	}

	if (product.value.customFields?.kippie_deposit_fields_deposit_product) {
		await promptDepositProduct(product.value.customFields.kippie_deposit_fields_deposit_product);
	}

	if (!!product.value.crossSellings && product.value.crossSellings.length > 0) {
		await promptCrossSell(product.value.crossSellings[0]);
	}

	if (selectPickupLocation && !selectedPickupLocation.value) {
		await drawerProvider.showDrawer(PickupLocationPrompt, {
			drawerTitle: "Kies een afhaallocatie"
		});
	}

	promptContinueShopping();

	const thumbUrl =
		(product.value.cover || product.value.media?.[0])?.media.thumbnails?.find(
			(thumbnail) => thumbnail.width === 280
		)?.url || (product.value.cover || product.value.media?.[0])?.media?.url;

	push.success({
		props: {
			isAddToCart: true,
			wholeProduct: product.value,
			product: {
				label: product.value.name,
				thumbUrl
			}
		}
	});
};
</script>

<template>
	<slot :on-add-to-cart="onAddToCart" />
</template>
